import {
  Card,
  Label,
  LoadingSpinner,
  NumberInput,
  PrimaryButton,
  SelectField,
  Spacings,
  TextInput,
} from "@commercetools-frontend/ui-kit";
import { TypeFormat } from "@wttb/product-configurator";
import { useFormik } from "formik";

import { ModalContextState, useUI } from "../../../common/contexts/ui-context";
import { useUpdateSettings } from "../hooks/use-update-settings";
import { Settings } from "../types";

const formatOptions = Object.values(TypeFormat).map((v) => ({
  value: v,
  label: v,
}));

export const SettingsEditForm: React.FC = () => {
  const { modalData: settings, closeModal } = useUI() as ModalContextState & {
    modalData: Settings;
  };
  const isNew = settings.id === "new";
  const updateSettings = useUpdateSettings(settings.id);

  const formik = useFormik<Omit<Settings, "id">>({
    initialValues: {
      key: settings.key,
      format: settings.format,
      value: settings.value,
      uplift: settings.uplift,
    },
    onSubmit: async (values) => {
      await updateSettings.mutateAsync(values);
      if (settings.id !== "new") {
        closeModal();
      }
    },
  });

  return (
    <Card type="flat" insetScale="none">
      <form onSubmit={formik.handleSubmit}>
        <Spacings.Stack>
          <Spacings.Stack scale="xs">
            <Label>Key</Label>
            <TextInput
              id="key"
              name="key"
              value={formik.values.key}
              onChange={formik.handleChange}
            />
          </Spacings.Stack>

          <Spacings.Stack scale="xs">
            <Label>Value</Label>
            <TextInput
              id="value"
              name="value"
              value={formik.values.value}
              onChange={formik.handleChange}
            />
          </Spacings.Stack>

          <SelectField
            id="format"
            title="Value format"
            value={formik.values.format}
            options={formatOptions}
            onChange={(value) => {
              formik.handleChange(value);

              if (value.target.value !== TypeFormat.Number) {
                formik.setFieldValue("uplift", null);
              }
            }}
            menuPortalZIndex={100000000}
            menuPortalTarget={document.body}
          />

          {formik.values.format === TypeFormat.Number && (
            <Spacings.Stack scale="xs">
              <Label>Uplift, %</Label>
              <NumberInput
                id="uplift"
                min={0}
                step="any"
                name="uplift"
                value={formik.values.uplift ?? 0}
                onChange={formik.handleChange}
              />
            </Spacings.Stack>
          )}

          <Spacings.Inline justifyContent="flex-end">
            {(formik.isSubmitting || updateSettings.isLoading) && <LoadingSpinner />}
            <PrimaryButton
              label={isNew ? "Create" : "Save"}
              type="submit"
              isDisabled={formik.isSubmitting || updateSettings.isLoading}
            />
          </Spacings.Inline>
        </Spacings.Stack>
      </form>
    </Card>
  );
};
